








































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_PASSWORD } from '@/modules/employee/store';
import { ChangePasswordParams } from '../types';
import { ApiResponse } from '@/components/types';

const Employee = namespace('employee');

@Component({})
export default class ChangePassword extends Vue {
  @Employee.Action(CHANGE_PASSWORD) changePassword!: (data: ChangePasswordParams) => Promise<ApiResponse>;

  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';

  error: any = null;
  loading: boolean = false;
  success: boolean = false;

  get confirm() {
    return {
      label: this.$i18n.t('label.confirmPassword'),
      input: this.confirmPassword
    };
  }

  reset(observer?: any) {
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.error = null;

    setTimeout(() => {
      observer && observer.reset();
    }, 100);
  }

  async save(): Promise<ApiResponse> {
    this.error = null;
    this.success = false;

    return this.changePassword({
      employee_id: this.$auth.user().id,
      new_password: this.newPassword,
      old_password: this.oldPassword
    });
  }

  passwordChanged(observer?: any) {
    this.success = true;
    this.reset(observer);
  }
}
